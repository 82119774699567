<template>
  <v-dialog
    v-model="isVisible"
    persistent
    max-width="600px"
  >
    <v-form
      ref="form"
      lazy-validation
    >
      <v-card>
        <v-card-title>
          <span class="text-h3">{{ $t("add_route_plan") }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                v-if="authUser.isAdmin"
                cols="4"
              >
                <!-- v-model="mdMake.is_active" -->
                <v-select
                  dense
                  :items="tenantList"
                  item-value="id"
                  item-text="name"
                  :label="$t('select_tenant_*')"
                  :rules="[rules.required]"
                  @change="onTenantChange"
                />
              </v-col>
              <v-col cols="4">
                <!-- v-model="mdMake.is_active" -->
                <v-select
                  dense
                  :items="vehicleList"
                  item-value="id"
                  item-text="name"
                  :label="$t('vehicles_*')"
                  :rules="[rules.required]"
                  return-object
                  @change="onVehicleChange"
                />
              </v-col>
              <v-col
                v-if="selected_vehicle.SOC"
                cols="2"
              >
                <v-text-field
                  dense
                  :value="selected_vehicle.SOC"
                  :label="$t('soc')"
                  disabled
                />
              </v-col>
              <v-col
                v-if="selected_vehicle.Range"
                cols="2"
              >
                <v-text-field
                  dense
                  :value="selected_vehicle.Range"
                  :label="$t('range')"
                  disabled
                />
              </v-col>
              <v-col
                v-if="selected_vehicle.isVlektra"
                cols="4"
              >
                <v-text-field
                  v-model="selected_vehicle.weight"
                  dense
                  type="number"
                  min="0"
                  max="100"
                  :label="$t('weight')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <div class="d-flex">
                  <v-text-field
                    v-if="startEndPnt['st']?.isLocation"
                    dense
                    :value="`${startEndPnt['st'].lat?.toFixed(
                      6
                    )} - ${startEndPnt['st'].lng?.toFixed(6)}`"
                    readonly
                    clearable
                    :label="$t('start_position')"
                    @click:clear="startEndPnt['st'] = {}"
                  />
                  <v-select
                    v-else
                    v-model="startEndPnt['st']"
                    dense
                    :loading="isLocLoading"
                    :items="locOptions"
                    item-text="location"
                    :label="$t('start_position')"
                    return-object
                    :rules="[rules.required]"
                  />
                  <set-point
                    v-model="startEndPnt['st']"
                    @getLocation="setCoordinates"
                  />
                </div>
              </v-col>
              <v-col cols="6">
                <div class="d-flex">
                  <v-text-field
                    v-if="startEndPnt['end']?.isLocation"
                    dense
                    :value="`${startEndPnt['end'].lat?.toFixed(
                      6
                    )} - ${startEndPnt['end'].lng?.toFixed(6)}`"
                    readonly
                    clearable
                    :label="$t('end_position')"
                    @click:clear="startEndPnt['end'] = {}"
                  />
                  <v-select
                    v-else
                    v-model="startEndPnt['end']"
                    dense
                    :loading="isLocLoading"
                    :items="locOptions"
                    item-text="location"
                    :label="$t('end_position')"
                    return-object
                    :rules="[rules.required]"
                  />
                  <set-point
                    v-model="startEndPnt['end']"
                    @getLocation="setCoordinates"
                  />
                </div>
              </v-col>
              <!-- <v-col cols="6">
                <v-text-field
                  v-model="vehicle['start-latitude']"
                  dense
                  :label="$t('latitude_*')"
                  :rules="[rules.required, rules.latlng]"
                />
              </v-col> -->
              <!-- <v-col cols="6">
                <v-text-field
                  v-model="vehicle['start-longitude']"
                  dense
                  :label="$t('longitude_*')"
                  :rules="[rules.required, rules.latlng]"
                />
              </v-col> -->
            </v-row>

            <div class="text-h4 mb-2 mt-4">
              {{ $t("add_drop_off") }}
            </div>
            <v-row
              v-for="(row, i) in dropOff"
              :key="i"
            >
              <v-col cols="9">
                <v-text-field
                  v-if="row.isLocation"
                  :key="i"
                  :value="`${row.DropLatitude} - ${row.DropLongitude}`"
                  dense
                  disabled
                  :label="$t('drop_off') + ` ${i + 1}`"
                />
                <v-select
                  v-else
                  :key="i"
                  v-model="row.drops"
                  dense
                  :disabled="!startEndPnt['st']"
                  :loading="isLocLoading"
                  :items="locOptions"
                  :item-disabled="checkPrevious"
                  item-text="location"
                  :label="$t('drop_off') + ` ${i + 1}`"
                  return-object
                  :rules="[rules.required]"
                  @change="setDropsoff(row)"
                />
              </v-col>

              <v-col cols="3">
                <div class="actionBtn">
                  <set-point
                    :disabled="!startEndPnt['st']"
                    :start-pnt="startEndPnt['st']"
                    :item="row"
                    @getLocation="setCoordinates"
                  />
                  <v-btn
                    v-if="!dropOff[i + 1]"
                    color="primary"
                    dark
                    x-small
                    @click="addDropOff(i + 1)"
                  >
                    <!-- @click="() => dropOff.push({ lat: '', lng: '' })" -->
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                  <v-btn
                    v-if="i >= 1"
                    color="danger"
                    dark
                    x-small
                    @click="() => dropOff.pop()"
                  >
                    <v-icon small>
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <small>{{ $t("indicates_required_field") }}</small>
          <v-spacer />
          <v-btn
            color="red darken-1"
            text
            @click="toggle('close')"
          >
            Close
          </v-btn>
          <v-btn
            color="primary"
            text
            :loading="isLoadingSave"
            @click="setPathPlan()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { validationRules } from "@/mixins/validationRules";
// import axios from "axios";

export default {
  components: {
    setPoint: () => import("./SetPointModal.vue"),
  },
  mixins: [validationRules],
  props: {
    isVisible: Boolean,
    toggle: {
      type: Function,
      default() {
        return void 0;
      },
    },
  },

  data() {
    return {
      isLoadingSave: false,
      vehicleList: [],
      vehicle: {
        Wt: 10000,
        type: "Local",
        Skill: null,
        capacity: 999999999,
        "total-km": null,
        "fixed-cost": 1,
        "start-time": "2024-01-01T07:00:27.000Z",
        "end-time": "2024-01-31T18:59:00.000Z",
        "vehicle-id": "orko-vehicle",
        "cost-per-km": 10,
        "parking-cost": 0,
        "cost-per-hour": 10,
        "start-latitude": null,
        "start-longitude": null,
        "end-latitude": null,
        "end-longitude": null,
        // "start-latitude": 24.9610826,
        // "start-longitude": 67.1667046,
        // "end-latitude": 24.9610826,
        // "end-longitude": 67.1667046,
        "speed-multiplier": 0.7,
        "number-of-vehicles": 1,
        "waiting-cost-per-hour": 0,
      },
      startEndPnt: {},
      //
      dropOff: [],

      mustang_vehicle: ["MustangEye - Vehicle 1", "MustangEye - Vehicle 2"],
      vlektra_vehicle: [
        {
          id: 1,
          name: "Saqib - Retro",
          SOC: "70%",
          Range: "36.26",
          isVlektra: true,
        },
        {
          id: 2,
          name: "Junaid Arif - Retro",
          SOC: "79%",
          Range: "44.92",
          isVlektra: true,
        },
        {
          id: 3,
          name: "Noman Fakhar - Retro",
          SOC: "82%",
          Range: "58.79",
          isVlektra: true,
        },
        {
          id: 4,
          name: "Kabir - Retro",
          SOC: "95%",
          Range: "41.85",
          isVlektra: true,
        },
        {
          id: 5,
          name: "Raheel - Bolt",
          SOC: "89%",
          Range: "58.40",
          isVlektra: true,
        },
        {
          id: 6,
          name: "Habib Jano - Bolt",
          SOC: "88%",
          Range: "50.94",
          isVlektra: true,
        },
        {
          id: 7,
          name: "Bilal Raza - Bolt",
          SOC: "99%",
          Range: "66.30",
          isVlektra: true,
        },
        {
          id: 8,
          name: "Fazal Bukhari - Bolt",
          SOC: "74%",
          Range: "68.90",
          isVlektra: true,
        },
      ],
      selected_vehicle: {},
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      clientsList: "meta/clientsList",
      tenantList: "tenantsManagement/getTenantsList",
      locOptions: "truckistanRoute/getLocationOptions",
      isLocLoading: "truckistanRoute/getLocationsLoading",
    }),
    getHeaders() {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append(
        "Authorization",
        "Bearer 72233e556bddf0767ba73ce0f5ff4debe7c33d6fce6e7104c46800a8a96a9109b53067a217361ca7f00b5232530caa835f597314dd713dc80d602b55f775d4dee304f8fa42fafa71d88763f07fed0d91cb115e979c21972516384b773a9db8421a8d59b97f0c956876684ab891d78f9676e8d6d217829f0ab3583fe22698811b"
      );
      return headers;
    },
    //
    enLoc() {
      if (this.startEndPnt.st) {
        let loc = this.startEndPnt.st.location;
        return this.locOptions.filter((r) => r.location !== loc);
      }
      return [];
    },
  },
  async mounted() {
    if (!this.authUser.isAdmin) {
      if (this.authUser.user_type_in_listing == "Mustangeye") {
        this.vehicleList = this.mustang_vehicle;
      } else if (this.authUser.user_type_in_listing == "Vlektra") {
        this.vehicleList = this.vlektra_vehicle;
      }
    }

    this.addDropOff(0);
    //
    // //
    // this.dropOff.push({
    //   Wt: 10,
    //   QTY: 1,
    //   Customer: "Customer 1",
    //   ConsignNo: 1001,
    //   OpenTime1: "2024-01-01T05:00:00.000Z",
    //   CloseTime1: "2024-01-31T05:00:00.000Z",
    //   "c-priority": 0,
    //   BookingDate: "2024-01-05T06:51:25.000Z",
    //   DropLatitude: "24.8785663",
    //   DropLongitude: "67.0615239",
    //   PickupLatitude: "24.9610826",
    //   PickupLongitude: "67.1667046",
    //   ReceiverAddress: "abc",
    //   UnloadMinsAtCustomer: 10,
    //   SenderAddress: "abc",
    //   Skill: "",
    // });
    // this.dropOff.push({
    //   Wt: 10,
    //   QTY: 1,
    //   Customer: "Customer 2",
    //   ConsignNo: 1002,
    //   OpenTime1: "2024-01-01T05:00:00.000Z",
    //   CloseTime1: "2024-01-31T05:00:00.000Z",
    //   "c-priority": 0,
    //   BookingDate: "2024-01-05T06:51:25.000Z",
    //   DropLatitude: "24.8625848",
    //   DropLongitude: "67.0149429",
    //   PickupLatitude: "24.9610826",
    //   PickupLongitude: "67.1667046",
    //   ReceiverAddress: "saddar",
    //   UnloadMinsAtCustomer: 10,
    //   SenderAddress: "amroha society",
    //   Skill: "",
    // });
    // this.dropOff.push({
    //   Wt: 10,
    //   QTY: 1,
    //   Customer: "Customer 3",
    //   ConsignNo: 1003,
    //   OpenTime1: "2024-01-01T05:00:00.000Z",
    //   CloseTime1: "2024-01-31T05:00:00.000Z",
    //   "c-priority": 0,
    //   BookingDate: "2024-01-05T06:51:25.000Z",
    //   DropLatitude: "24.8489421",
    //   DropLongitude: "66.9947915",
    //   PickupLatitude: "24.9610826",
    //   PickupLongitude: "67.1667046",
    //   ReceiverAddress: "tower",
    //   UnloadMinsAtCustomer: 10,
    //   SenderAddress: "amroha society",
    //   Skill: "",
    // });
    //
  },

  methods: {
    setPathPlan() {
      // if (this.$refs.form.validate()) {
      //   this.isLoadingSave = true;
      //   // set Time
      //   this.vehicle["start-time"] = new Date();
      //   this.vehicle["end-time"] = this.addDays(new Date(), 10);
      //   // Location
      //   this.vehicle["start-latitude"] = this.startEndPnt.st.lat;
      //   this.vehicle["start-longitude"] =
      //     this.startEndPnt.st.long || this.startEndPnt.st.lng;
      //   this.vehicle["end-latitude"] = this.startEndPnt.end.lat;
      //   this.vehicle["end-longitude"] =
      //     this.startEndPnt.end.long || this.startEndPnt.end.lng;
      //   const payload = {
      //     Jobs: this.dropOff,
      //     Vehicles: [this.vehicle],
      //   };
      //   this.$store
      //     .dispatch("truckistanRoute/setRoutePlan", payload)
      //     .then((res) => {
      //       this.$store.dispatch("alerts/success", res.message);
      //       localStorage.setItem("modelId", res.modelId);
      //       localStorage.setItem("tracker", new Date());
      //       this.$emit("progress", true);
      //       this.toggle("save");
      //       setTimeout(() => {
      //         this.$store.dispatch(
      //           "alerts/success",
      //           "Tracking Data is Fetched Check your Route"
      //         );
      //         this.fetchData(res.modelId);
      //       }, 5000);
      //     })
      //     .catch((error) => {
      //       this.$store.dispatch("alerts/error", error.response?.data?.message);
      //     })
      //     .finally(() => (this.isLoadingSave = false));
      // }
    },
    //
    fetchData(v) {
      const payload = {
        modelName: v,
      };
      this.$store.dispatch("truckistanRoute/getPlanOutput", payload);
    },
    //
    addDays(theDate, days) {
      return new Date(theDate.getTime() + days * 24 * 60 * 60 * 1000);
    },
    addDropOff(len) {
      const p = {
        Wt: 10,
        QTY: 1,
        Customer: `Customer ${len + 1}`,
        ConsignNo: 1000 + (len + 1),
        OpenTime1: new Date(),
        CloseTime1: this.addDays(new Date(), 3),
        BookingDate: this.addDays(new Date(), 5),
        "c-priority": 0,
        DropLatitude: null,
        DropLongitude: null,
        PickupLatitude: null,
        PickupLongitude: null,
        // DropLatitude: 24.8785663,
        // DropLongitude: 67.0615239,
        // PickupLatitude: 24.8785663,
        // PickupLongitude: 67.0615239,
        ReceiverAddress: "abc",
        UnloadMinsAtCustomer: 10,
        SenderAddress: "abc",
        Skill: "",
      };
      this.dropOff.push(p);
    },

    //
    checkPrevious(v) {
      const name = v.location;
      const row = this.dropOff.map((r) => r.drops?.location);
      // add start and End point
      if (this.startEndPnt.st) {
        row.push(this.startEndPnt.st.location);
      }
      if (this.startEndPnt.end) {
        row.push(this.startEndPnt.end.location);
      }
      return row.includes(name);
    },
    onVehicleChange(v) {
      if (v.isVlektra) {
        this.selected_vehicle = v;
      }
    },
    onTenantChange(v) {
      this.selected_vehicle = {};
      const params = { tenant_id: v };
      this.$store.dispatch("truckistanRoute/getLocations", params);

      if (v) {
        const t = this.tenantList.find((r) => r.id == v);
        if (t.name == "Mustangeye") {
          this.vehicleList = this.mustang_vehicle;
        } else if (t.name == "Vlektra") {
          this.vehicleList = this.vlektra_vehicle;
        } else {
          this.vehicleList = [];
        }
      }
    },
    setDropsoff(row) {
      row.DropLatitude = row.drops.lat;
      row.DropLongitude = row.drops.long;
      // row.PickupLatitude = row.drops.lat;
      // row.PickupLongitude = row.drops.long;
      row.PickupLatitude = this.startEndPnt["st"].lat;
      row.PickupLongitude = this.startEndPnt["st"].long;
    },
    //
    setCoordinates() {
      this.$forceUpdate();
    },
  },
};
</script>
<style lang="sass" scoped>
.actionBtn
  display: flex
  gag:0.5rem !important
  .danger
    background: red

:deep .v-select__selection--disabled
  color:  #000 !important
</style>
